<template>
  <div>
    <s-drawer
      :visible="visible"
      append-to-body
      show-close
      class="select-address-type"
      @update:visible="closeDrawer"
    >
      <template #title>
        <div>
        </div>
      </template>
      <div>
        <div class="select-address-type__title">
          {{ language.SHEIN_KEY_PWA_35395 }}
        </div>
        <div>
          <div class="odd-option">
            <s-radio
              v-model="selectValue"
              label="odd"
            >
              <span class="odd-title">{{ language.SHEIN_KEY_PWA_35396 }}</span>
              <span class="green-tag">
                {{ language.SHEIN_KEY_PWA_35397 }}
              </span>
              <span class="green-tag">
                {{ language.SHEIN_KEY_PWA_35459 }}
              </span>
            </s-radio>
            <div class="sub-container">
              <div class="sub-title">
                {{ language.SHEIN_KEY_PWA_35398 }}
              </div>
              <div class="benefit-content">
                <div class="benefit-content-title">
                  {{ language.SHEIN_KEY_PWA_35399 }}
                </div>
                <div class="benefit-text">
                  <Icon
                    name="sui_icon_success_18px"
                    size="16px"
                    color="#198055"
                  />
                  {{ language.SHEIN_KEY_PWA_35400 }}
                </div>
              </div>
            </div>
          </div>
          <div class="shein-option">
            <s-radio
              v-model="selectValue"
              label="shein"
            >
              <span class="shein-title">{{ language.SHEIN_KEY_PWA_35403 }}</span>
            </s-radio>
            <div class="sub-container">
              <div class="sub-title">
                {{ language.SHEIN_KEY_PWA_35401 }}
              </div>
              <div class="benefit-content">
                <div class="benefit-content-title">
                  {{ language.SHEIN_KEY_PWA_35402 }}
                </div>
                <div
                  v-for="(item, index) in displayFeildArr"
                  :key="index"
                  class="benefit-text"
                >
                  <Icon
                    name="sui_icon_failed_14px"
                    size="16px"
                    color="#BBBBBB"
                  />
                  {{ item }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <template #footer>
        <div>
          <s-button
            :type="[ 'primary', 'H72PX' ]"
            width="100%"
            @click="handleConfirm"
          >
            {{ language.SHEIN_KEY_PWA_23012 }}
          </s-button>
        </div>
      </template>
    </s-drawer>

    <SLoading
      type="curpage"
      :show="loadingShow"
    />
  </div>
</template>

<script>
import { SDrawer } from '@shein-aidc/sui-drawer/mobile'
import { SButton } from '@shein-aidc/sui-button/mobile'
import { SRadio } from '@shein-aidc/sui-radio/mobile'
import { defineComponent } from 'vue'
import { TRANSPORT_TYPE_SHOP } from 'public/src/js/constants'
import { getDisabledConfig } from 'public/src/pages/trackNew/utils'
import schttp from 'public/src/services/schttp'
import { Icon } from '@shein-aidc/icon-vue3'
import { SLoading } from '@shein-aidc/sui-loading/mobile'
import { daEventCenter as daEventCenterV2 } from '@shein-aidc/basis-sa-event-center'

let { UPDATE_SHIPPED_ADDRESS } = typeof window != 'undefined' ? gbCommonInfo : {}

export default defineComponent({
  name: 'EditAddressSelect',
  components: {
    SRadio,
    SButton,
    SDrawer,
    Icon,
    SLoading
  },
  props: {
    language: {
      type: Object,
      default: function () {
        return {}
      },
    },
    order: {
      type: Object,
      default: function () {
        return {}
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectValue: 'odd',
      displayFeildArr: [],
      loadingShow: false
    }
  },
  computed: {
    disabledAddressArr () {
      let { payment_method, orderStatus } = this.order || {}
      let disabledAddress = ['country']

      let disabledAddressArr = getDisabledConfig({
        orderInfo: this.order,
        TRANSPORT_TYPE_SHOP,
        updateShippedAddress: UPDATE_SHIPPED_ADDRESS?.[0] || {},
        disabledAddress: disabledAddress
      })
      disabledAddress = disabledAddressArr?.length ? disabledAddressArr : disabledAddress
      if (payment_method === 'cod' && [6, 4].includes(+orderStatus)) {
        disabledAddress = [...new Set([...disabledAddress, 'tel', 'standbyTel'])]
      }

      return disabledAddress
    }
  },
  async mounted() {
    const { billno = '' } = this.order
    daEventCenterV2.triggerNotice({
      id: 'expose_odd_address:simple',
      data: {
        orderid: billno
      }
    })
    await this.fetchAddressBenefitName()
  },
  methods: {
    closeDrawer() {
      this.$emit('update:visible', false)
    },
    handleConfirm() {
      const { billno = '' } = this.order
      daEventCenterV2.triggerNotice({
        id: 'click_odd_address:simple',
        data: {
          orderid: billno,
          button: this.selectValue
        }
      })
      if (this.selectValue == 'shein') {
        this.$emit('select-shein-edit')
      } else {
        this.$emit('select-odd-edit')
      }
    },
    async fetchAddressBenefitName() {
      this.loadingShow = true
      try {
        const result = await schttp({

          url: '/api/user/addressbook/addressFormConfigByInfo/get',
          params: {
            country_region_id: this.order?.country_id,
            distribution_type: 1,
            scene_type: 1,
            terminal_type: 2,
          }
        })
        if (result.code == 0) {
          let displayArr = []
          const addresConfigList = result?.info?.address_rule_field_list || []
          this.disabledAddressArr.forEach(detailItem => {
            const addressItem = addresConfigList.find(item => item.field_name == detailItem)
            if (addressItem){
              displayArr.push(addressItem.display_name || '')
            }
          })
          this.displayFeildArr = displayArr
        }
      } catch (e) {
        console.error(e)
      } finally {
        this.loadingShow = false
      }
    }
  },
})
</script>

<style lang="less">
.odd-option,
.shein-option {
  .sui-radio_normal {
    margin-right: 0;
  }
}
.select-address-type {
  .sui-drawer__header_normal {
    border-bottom: none;
  }
  .sui-drawer__normal-footer {
    padding: 24/75rem;
    height: 100%;
    margin-top: 0;
  }
}
</style>
<style lang="less" scoped>
.select-address-type {
  &__title {
    .font-dpr(28px);
    font-weight: bold;
    text-align: center;
  }
}
.green-tag {
  color: @sui_color_safety;
  background: @sui_color_safety_bg;
  .font-dpr(20px);
  padding: 8/75rem;
  margin-left: 8/75rem;
}
.shein-title,
.odd-title {
  .font-dpr(28px);
}
.sub-title {
  color: @sui_color_gray_dark3;
  .font-dpr(24px);
}
.benefit-content {
  padding: 16/75rem 24/75rem;
  background: #F6F6F6;
}
.benefit-content-title,
.benefit-text {
  color: @sui_color_gray_dark2;
}
.odd-option,
.shein-option {
  padding: 16/75rem 24/75rem;
}
.sub-container {
  padding-left: 50/75rem;
}
</style>
